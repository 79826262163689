import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PUBLIC_RESERVATIONS_CONFIRM } from 'Consts/components';

import { translate } from 'Utils/language';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Button from 'Components/layout/Button';

export default class PublicReservationsConfirm extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        currentLanguage: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, currentLanguage } = this.props;

        return (
            <StyledComponent
                className={classnames({
                    'component-reservations-confirm': true,
                })}
                styles={require('./styles')}
            >
                <Head
                    title="Rezerwacje treningów"
                    description="Rezerwacje treningów FitAdept"
                />
                <div className="content content-gym-select">
                    <LayoutContainer>
                        <div className="body">
                            <h1 className="headline">
                                {translate(`${PUBLIC_RESERVATIONS_CONFIRM}_content_body_headline`, currentLanguage, location)}
                            </h1>
                            <p className="subheadline">
                                {translate(`${PUBLIC_RESERVATIONS_CONFIRM}_content_body_subheadline`, currentLanguage, location)}
                            </p>
                            <a href="https://fitadept.com">
                                <Button
                                    styleVersion={2}
                                    size="large"
                                >
                                    {translate(`${PUBLIC_RESERVATIONS_CONFIRM}_content_body_button`, currentLanguage, location)}
                                </Button>
                            </a>
                        </div>
                    </LayoutContainer>
                </div>
            </StyledComponent>
        );
    }
}
